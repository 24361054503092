// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts,import_extension=none"
// @generated from file payments/v1alpha/invoices.proto (package com.seed.payments.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateInvoiceRequest, DecodeInvoiceRequest, GetLnAddressRequest, Invoice, InvoiceResponse, ListInvoicesRequest, ListInvoicesResponse, LNAddress, PayInvoiceRequest, RequestLud6InvoiceRequest, UpdateLNAddressRequest } from "./invoices_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * Invoices service deals with invoices and payments of invoices.
 *
 * @generated from service com.seed.payments.v1alpha.Invoices
 */
export const Invoices = {
  typeName: "com.seed.payments.v1alpha.Invoices",
  methods: {
    /**
     * CreateInvoice creates a local invoice. Used to get paid. Typicaly 
     * seed users will call this remotely bc they don't know the lnaddress
     * of the receiver, just their seed account. The local node, upon the 
     * request, issues an invoice.
     *
     * @generated from rpc com.seed.payments.v1alpha.Invoices.CreateInvoice
     */
    createInvoice: {
      name: "CreateInvoice",
      I: CreateInvoiceRequest,
      O: InvoiceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * PayInvoice Pays a bolt11 invoice.
     *
     * @generated from rpc com.seed.payments.v1alpha.Invoices.PayInvoice
     */
    payInvoice: {
      name: "PayInvoice",
      I: PayInvoiceRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * ListPaidInvoices Pays a bolt11 invoice.
     *
     * @generated from rpc com.seed.payments.v1alpha.Invoices.ListPaidInvoices
     */
    listPaidInvoices: {
      name: "ListPaidInvoices",
      I: ListInvoicesRequest,
      O: ListInvoicesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DecodeInvoice decodes an invoice .
     *
     * @generated from rpc com.seed.payments.v1alpha.Invoices.DecodeInvoice
     */
    decodeInvoice: {
      name: "DecodeInvoice",
      I: DecodeInvoiceRequest,
      O: Invoice,
      kind: MethodKind.Unary,
    },
    /**
     * ListReceivedInvoices Lists received payments.
     *
     * @generated from rpc com.seed.payments.v1alpha.Invoices.ListReceivedInvoices
     */
    listReceivedInvoices: {
      name: "ListReceivedInvoices",
      I: ListInvoicesRequest,
      O: ListInvoicesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * LNURL service provides LNURL (https://github.com/lnurl) compatibility.
 *
 * @generated from service com.seed.payments.v1alpha.LNURL
 */
export const LNURL = {
  typeName: "com.seed.payments.v1alpha.LNURL",
  methods: {
    /**
     * Request an invoice following the LNURL lud6 protocol 
     * (https://github.com/lnurl/luds/blob/luds/06.md). This does not require the
     * caller to log in anywhere. Used to pay.
     *
     * @generated from rpc com.seed.payments.v1alpha.LNURL.RequestLud6Invoice
     */
    requestLud6Invoice: {
      name: "RequestLud6Invoice",
      I: RequestLud6InvoiceRequest,
      O: InvoiceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetLnAddress gets the lnaddress (https://lightningaddress.com/) associated
     * with a wallet. Not all wallets are lnaddress compatible.
     *
     * @generated from rpc com.seed.payments.v1alpha.LNURL.GetLnAddress
     */
    getLnAddress: {
      name: "GetLnAddress",
      I: GetLnAddressRequest,
      O: LNAddress,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateLNAddress change the lnaddress of a specific wallet.
     * LNaddress must be globally unique (like email addresses).
     *
     * @generated from rpc com.seed.payments.v1alpha.LNURL.UpdateLNAddress
     */
    updateLNAddress: {
      name: "UpdateLNAddress",
      I: UpdateLNAddressRequest,
      O: LNAddress,
      kind: MethodKind.Unary,
    },
  }
} as const;

