// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts,import_extension=none"
// @generated from file activity/v1alpha/subscriptions.proto (package com.seed.activity.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListSubscriptionsRequest, ListSubscriptionsResponse, SubscribeRequest, UnsubscribeRequest } from "./subscriptions_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * Subscriptions service provides subscription capabilities.
 *
 * @generated from service com.seed.activity.v1alpha.Subscriptions
 */
export const Subscriptions = {
  typeName: "com.seed.activity.v1alpha.Subscriptions",
  methods: {
    /**
     * Subscribe to a document or space.
     *
     * @generated from rpc com.seed.activity.v1alpha.Subscriptions.Subscribe
     */
    subscribe: {
      name: "Subscribe",
      I: SubscribeRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Remove a subscription.
     *
     * @generated from rpc com.seed.activity.v1alpha.Subscriptions.Unsubscribe
     */
    unsubscribe: {
      name: "Unsubscribe",
      I: UnsubscribeRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Lists active subscriptions.
     *
     * @generated from rpc com.seed.activity.v1alpha.Subscriptions.ListSubscriptions
     */
    listSubscriptions: {
      name: "ListSubscriptions",
      I: ListSubscriptionsRequest,
      O: ListSubscriptionsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

