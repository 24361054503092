// @generated by protoc-gen-es v1.4.1 with parameter "target=ts,import_extension=none"
// @generated from file daemon/v1alpha/daemon.proto (package com.seed.daemon.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * State describes various states of the daemon.
 *
 * @generated from enum com.seed.daemon.v1alpha.State
 */
export enum State {
  /**
   * Daemon in starting up and it not ready to use yet.
   *
   * @generated from enum value: STARTING = 0;
   */
  STARTING = 0,

  /**
   * Daemon is running a data migration, which may take a while.
   * Callers should poll and wait until the daemon becomes ACTIVE.
   *
   * @generated from enum value: MIGRATING = 1;
   */
  MIGRATING = 1,

  /**
   * Daemon is active and ready to use.
   *
   * @generated from enum value: ACTIVE = 3;
   */
  ACTIVE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(State)
proto3.util.setEnumType(State, "com.seed.daemon.v1alpha.State", [
  { no: 0, name: "STARTING" },
  { no: 1, name: "MIGRATING" },
  { no: 3, name: "ACTIVE" },
]);

/**
 * Request to generate mnemonic words.
 *
 * @generated from message com.seed.daemon.v1alpha.GenMnemonicRequest
 */
export class GenMnemonicRequest extends Message<GenMnemonicRequest> {
  /**
   * Optional. Number of mnemonic words to encode the seed.
   * Usually 12 or 24 words.
   * By default 12 words are generated.
   *
   * @generated from field: int32 word_count = 1;
   */
  wordCount = 0;

  constructor(data?: PartialMessage<GenMnemonicRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.GenMnemonicRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "word_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenMnemonicRequest {
    return new GenMnemonicRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenMnemonicRequest {
    return new GenMnemonicRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenMnemonicRequest {
    return new GenMnemonicRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenMnemonicRequest | PlainMessage<GenMnemonicRequest> | undefined, b: GenMnemonicRequest | PlainMessage<GenMnemonicRequest> | undefined): boolean {
    return proto3.util.equals(GenMnemonicRequest, a, b);
  }
}

/**
 * Response with the generated mnemonic.
 *
 * @generated from message com.seed.daemon.v1alpha.GenMnemonicResponse
 */
export class GenMnemonicResponse extends Message<GenMnemonicResponse> {
  /**
   * The list of human-friendly words that can be used to backup the seed. These
   * words must be stored in a secret place by the user.
   *
   * @generated from field: repeated string mnemonic = 1;
   */
  mnemonic: string[] = [];

  constructor(data?: PartialMessage<GenMnemonicResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.GenMnemonicResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mnemonic", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenMnemonicResponse {
    return new GenMnemonicResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenMnemonicResponse {
    return new GenMnemonicResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenMnemonicResponse {
    return new GenMnemonicResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenMnemonicResponse | PlainMessage<GenMnemonicResponse> | undefined, b: GenMnemonicResponse | PlainMessage<GenMnemonicResponse> | undefined): boolean {
    return proto3.util.equals(GenMnemonicResponse, a, b);
  }
}

/**
 * Request to register a new account key derived from the mnemonic.
 *
 * @generated from message com.seed.daemon.v1alpha.RegisterKeyRequest
 */
export class RegisterKeyRequest extends Message<RegisterKeyRequest> {
  /**
   * Required. The list of BIP-39 mnemonic words.
   *
   * @generated from field: repeated string mnemonic = 1;
   */
  mnemonic: string[] = [];

  /**
   * Optional. Passphrase for the seed.
   *
   * @generated from field: string passphrase = 2;
   */
  passphrase = "";

  /**
   * Required. Private name/label for the signing key, to easily identify keys when they are more than one.
   * Name must be unique across all the registered keys.
   *
   * @generated from field: string name = 3;
   */
  name = "";

  constructor(data?: PartialMessage<RegisterKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.RegisterKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mnemonic", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "passphrase", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterKeyRequest {
    return new RegisterKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterKeyRequest {
    return new RegisterKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterKeyRequest {
    return new RegisterKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterKeyRequest | PlainMessage<RegisterKeyRequest> | undefined, b: RegisterKeyRequest | PlainMessage<RegisterKeyRequest> | undefined): boolean {
    return proto3.util.equals(RegisterKeyRequest, a, b);
  }
}

/**
 * Request to get basic information about the running daemon.
 *
 * @generated from message com.seed.daemon.v1alpha.GetInfoRequest
 */
export class GetInfoRequest extends Message<GetInfoRequest> {
  constructor(data?: PartialMessage<GetInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.GetInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInfoRequest {
    return new GetInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInfoRequest {
    return new GetInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInfoRequest {
    return new GetInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetInfoRequest | PlainMessage<GetInfoRequest> | undefined, b: GetInfoRequest | PlainMessage<GetInfoRequest> | undefined): boolean {
    return proto3.util.equals(GetInfoRequest, a, b);
  }
}

/**
 * Request to force the syncing process.
 *
 * @generated from message com.seed.daemon.v1alpha.ForceSyncRequest
 */
export class ForceSyncRequest extends Message<ForceSyncRequest> {
  constructor(data?: PartialMessage<ForceSyncRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.ForceSyncRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ForceSyncRequest {
    return new ForceSyncRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ForceSyncRequest {
    return new ForceSyncRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ForceSyncRequest {
    return new ForceSyncRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ForceSyncRequest | PlainMessage<ForceSyncRequest> | undefined, b: ForceSyncRequest | PlainMessage<ForceSyncRequest> | undefined): boolean {
    return proto3.util.equals(ForceSyncRequest, a, b);
  }
}

/**
 * Request to delete all keys.
 *
 * @generated from message com.seed.daemon.v1alpha.DeleteAllKeysRequest
 */
export class DeleteAllKeysRequest extends Message<DeleteAllKeysRequest> {
  constructor(data?: PartialMessage<DeleteAllKeysRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.DeleteAllKeysRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAllKeysRequest {
    return new DeleteAllKeysRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAllKeysRequest {
    return new DeleteAllKeysRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAllKeysRequest {
    return new DeleteAllKeysRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAllKeysRequest | PlainMessage<DeleteAllKeysRequest> | undefined, b: DeleteAllKeysRequest | PlainMessage<DeleteAllKeysRequest> | undefined): boolean {
    return proto3.util.equals(DeleteAllKeysRequest, a, b);
  }
}

/**
 * Request to list signing keys.
 *
 * @generated from message com.seed.daemon.v1alpha.ListKeysRequest
 */
export class ListKeysRequest extends Message<ListKeysRequest> {
  constructor(data?: PartialMessage<ListKeysRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.ListKeysRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListKeysRequest {
    return new ListKeysRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListKeysRequest {
    return new ListKeysRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListKeysRequest {
    return new ListKeysRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListKeysRequest | PlainMessage<ListKeysRequest> | undefined, b: ListKeysRequest | PlainMessage<ListKeysRequest> | undefined): boolean {
    return proto3.util.equals(ListKeysRequest, a, b);
  }
}

/**
 * Response with the list of registered signing keys.
 *
 * @generated from message com.seed.daemon.v1alpha.ListKeysResponse
 */
export class ListKeysResponse extends Message<ListKeysResponse> {
  /**
   * List of registered keys.
   *
   * @generated from field: repeated com.seed.daemon.v1alpha.NamedKey keys = 1;
   */
  keys: NamedKey[] = [];

  constructor(data?: PartialMessage<ListKeysResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.ListKeysResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keys", kind: "message", T: NamedKey, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListKeysResponse {
    return new ListKeysResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListKeysResponse {
    return new ListKeysResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListKeysResponse {
    return new ListKeysResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListKeysResponse | PlainMessage<ListKeysResponse> | undefined, b: ListKeysResponse | PlainMessage<ListKeysResponse> | undefined): boolean {
    return proto3.util.equals(ListKeysResponse, a, b);
  }
}

/**
 * Request to change the key name.
 *
 * @generated from message com.seed.daemon.v1alpha.UpdateKeyRequest
 */
export class UpdateKeyRequest extends Message<UpdateKeyRequest> {
  /**
   * Current name of the key.
   *
   * @generated from field: string current_name = 1;
   */
  currentName = "";

  /**
   * New name for the key.
   *
   * @generated from field: string new_name = 2;
   */
  newName = "";

  constructor(data?: PartialMessage<UpdateKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.UpdateKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "current_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "new_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateKeyRequest {
    return new UpdateKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateKeyRequest {
    return new UpdateKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateKeyRequest {
    return new UpdateKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateKeyRequest | PlainMessage<UpdateKeyRequest> | undefined, b: UpdateKeyRequest | PlainMessage<UpdateKeyRequest> | undefined): boolean {
    return proto3.util.equals(UpdateKeyRequest, a, b);
  }
}

/**
 * Request to delete an existing key.
 *
 * @generated from message com.seed.daemon.v1alpha.DeleteKeyRequest
 */
export class DeleteKeyRequest extends Message<DeleteKeyRequest> {
  /**
   * Name of the key to delete.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.DeleteKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteKeyRequest {
    return new DeleteKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteKeyRequest {
    return new DeleteKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteKeyRequest {
    return new DeleteKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteKeyRequest | PlainMessage<DeleteKeyRequest> | undefined, b: DeleteKeyRequest | PlainMessage<DeleteKeyRequest> | undefined): boolean {
    return proto3.util.equals(DeleteKeyRequest, a, b);
  }
}

/**
 * Request to store blobs.
 *
 * @generated from message com.seed.daemon.v1alpha.StoreBlobsRequest
 */
export class StoreBlobsRequest extends Message<StoreBlobsRequest> {
  /**
   * Required. List of blobs to be stored.
   * The request is atomic: either all blobs are stored or none of them.
   *
   * @generated from field: repeated com.seed.daemon.v1alpha.Blob blobs = 1;
   */
  blobs: Blob[] = [];

  constructor(data?: PartialMessage<StoreBlobsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.StoreBlobsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "blobs", kind: "message", T: Blob, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StoreBlobsRequest {
    return new StoreBlobsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StoreBlobsRequest {
    return new StoreBlobsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StoreBlobsRequest {
    return new StoreBlobsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StoreBlobsRequest | PlainMessage<StoreBlobsRequest> | undefined, b: StoreBlobsRequest | PlainMessage<StoreBlobsRequest> | undefined): boolean {
    return proto3.util.equals(StoreBlobsRequest, a, b);
  }
}

/**
 * Response after storing blobs.
 *
 * @generated from message com.seed.daemon.v1alpha.StoreBlobsResponse
 */
export class StoreBlobsResponse extends Message<StoreBlobsResponse> {
  /**
   * List of CIDs for the stored blobs.
   * The order is the same as in the request.
   *
   * @generated from field: repeated string cids = 1;
   */
  cids: string[] = [];

  constructor(data?: PartialMessage<StoreBlobsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.StoreBlobsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StoreBlobsResponse {
    return new StoreBlobsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StoreBlobsResponse {
    return new StoreBlobsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StoreBlobsResponse {
    return new StoreBlobsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StoreBlobsResponse | PlainMessage<StoreBlobsResponse> | undefined, b: StoreBlobsResponse | PlainMessage<StoreBlobsResponse> | undefined): boolean {
    return proto3.util.equals(StoreBlobsResponse, a, b);
  }
}

/**
 * Raw blob to be stored.
 *
 * @generated from message com.seed.daemon.v1alpha.Blob
 */
export class Blob extends Message<Blob> {
  /**
   * Optional. The client can provide a CID for the blob (the server will verify it).
   * If not provided, the data is assumed to be DAG-CBOR encoded, and the server will generate a CID
   * using its default hash function.
   *
   * @generated from field: string cid = 1;
   */
  cid = "";

  /**
   * Required. Raw data of the blob.
   *
   * @generated from field: bytes data = 2;
   */
  data = new Uint8Array(0);

  constructor(data?: PartialMessage<Blob>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.Blob";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Blob {
    return new Blob().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Blob {
    return new Blob().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Blob {
    return new Blob().fromJsonString(jsonString, options);
  }

  static equals(a: Blob | PlainMessage<Blob> | undefined, b: Blob | PlainMessage<Blob> | undefined): boolean {
    return proto3.util.equals(Blob, a, b);
  }
}

/**
 * Info is a generic information about the running node.
 *
 * @generated from message com.seed.daemon.v1alpha.Info
 */
export class Info extends Message<Info> {
  /**
   * Current state of the daemon.
   *
   * @generated from field: com.seed.daemon.v1alpha.State state = 1;
   */
  state = State.STARTING;

  /**
   * Libp2p Peer ID of this node.
   *
   * @generated from field: string peer_id = 2;
   */
  peerId = "";

  /**
   * Start time of the node.
   *
   * @generated from field: google.protobuf.Timestamp start_time = 3;
   */
  startTime?: Timestamp;

  /**
   * The libp2p protocol ID that the daemon is using.
   *
   * @generated from field: string protocol_id = 4;
   */
  protocolId = "";

  constructor(data?: PartialMessage<Info>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.Info";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(State) },
    { no: 2, name: "peer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start_time", kind: "message", T: Timestamp },
    { no: 4, name: "protocol_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Info {
    return new Info().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Info {
    return new Info().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Info {
    return new Info().fromJsonString(jsonString, options);
  }

  static equals(a: Info | PlainMessage<Info> | undefined, b: Info | PlainMessage<Info> | undefined): boolean {
    return proto3.util.equals(Info, a, b);
  }
}

/**
 * Signing key with an internal name.
 *
 * @generated from message com.seed.daemon.v1alpha.NamedKey
 */
export class NamedKey extends Message<NamedKey> {
  /**
   * Public key in Multikey format.
   * https://www.w3.org/TR/vc-data-integrity/#multikey.
   *
   * @generated from field: string public_key = 1;
   */
  publicKey = "";

  /**
   * Private name for the key. Useful to identify the keys when there're more than one.
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * Account ID representation of this key.
   *
   * @generated from field: string account_id = 3;
   */
  accountId = "";

  constructor(data?: PartialMessage<NamedKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.daemon.v1alpha.NamedKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "public_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NamedKey {
    return new NamedKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NamedKey {
    return new NamedKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NamedKey {
    return new NamedKey().fromJsonString(jsonString, options);
  }

  static equals(a: NamedKey | PlainMessage<NamedKey> | undefined, b: NamedKey | PlainMessage<NamedKey> | undefined): boolean {
    return proto3.util.equals(NamedKey, a, b);
  }
}

