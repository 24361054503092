// @generated by protoc-gen-es v1.4.1 with parameter "target=ts,import_extension=none"
// @generated from file documents/v3alpha/documents.proto (package com.seed.documents.v3alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Empty, Message, proto3, protoInt64, Struct, Timestamp } from "@bufbuild/protobuf";

/**
 * Attrbites that can be used for sorting.
 *
 * @generated from enum com.seed.documents.v3alpha.SortAttribute
 */
export enum SortAttribute {
  /**
   * Sorting by activity time,
   * i.e. time of the latest comment or change.
   *
   * @generated from enum value: ACTIVITY_TIME = 0;
   */
  ACTIVITY_TIME = 0,

  /**
   * Sorting by name/title of the document.
   *
   * @generated from enum value: NAME = 1;
   */
  NAME = 1,

  /**
   * Sorting by path value.
   * When documents from multiple accounts are returned in the same listing,
   * the entire fully-qualified namespaced path is considered,
   * i.e. <account-id>/<path>.
   *
   * @generated from enum value: PATH = 2;
   */
  PATH = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SortAttribute)
proto3.util.setEnumType(SortAttribute, "com.seed.documents.v3alpha.SortAttribute", [
  { no: 0, name: "ACTIVITY_TIME" },
  { no: 1, name: "NAME" },
  { no: 2, name: "PATH" },
]);

/**
 * Request for getting a single document.
 *
 * @generated from message com.seed.documents.v3alpha.GetDocumentRequest
 */
export class GetDocumentRequest extends Message<GetDocumentRequest> {
  /**
   * Required. The ID of the account where the document is located.
   *
   * @generated from field: string account = 1;
   */
  account = "";

  /**
   * Required. Path of the document.
   * Empty string means root document.
   *
   * @generated from field: string path = 2;
   */
  path = "";

  /**
   * Optional. Exact version of the document to retrieve.
   * Empty version means "latest".
   *
   * @generated from field: string version = 3;
   */
  version = "";

  constructor(data?: PartialMessage<GetDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.GetDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDocumentRequest {
    return new GetDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDocumentRequest {
    return new GetDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDocumentRequest {
    return new GetDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDocumentRequest | PlainMessage<GetDocumentRequest> | undefined, b: GetDocumentRequest | PlainMessage<GetDocumentRequest> | undefined): boolean {
    return proto3.util.equals(GetDocumentRequest, a, b);
  }
}

/**
 * When GetDocument RPC fails due to an existing redirect, this message will be attached
 * to the built-in status message's details field. The client should handle the error,
 * and parse this message to follow the redirect.
 * The client must be careful to prevent redirect cycles and very deep redirect chains.
 *
 * @generated from message com.seed.documents.v3alpha.RedirectErrorDetails
 */
export class RedirectErrorDetails extends Message<RedirectErrorDetails> {
  /**
   * Account ID where the redirect points to.
   *
   * @generated from field: string target_account = 1;
   */
  targetAccount = "";

  /**
   * Path of the target document within the target account.
   *
   * @generated from field: string target_path = 2;
   */
  targetPath = "";

  constructor(data?: PartialMessage<RedirectErrorDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.RedirectErrorDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "target_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "target_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedirectErrorDetails {
    return new RedirectErrorDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedirectErrorDetails {
    return new RedirectErrorDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedirectErrorDetails {
    return new RedirectErrorDetails().fromJsonString(jsonString, options);
  }

  static equals(a: RedirectErrorDetails | PlainMessage<RedirectErrorDetails> | undefined, b: RedirectErrorDetails | PlainMessage<RedirectErrorDetails> | undefined): boolean {
    return proto3.util.equals(RedirectErrorDetails, a, b);
  }
}

/**
 * Request to create a new document change.
 *
 * @generated from message com.seed.documents.v3alpha.CreateDocumentChangeRequest
 */
export class CreateDocumentChangeRequest extends Message<CreateDocumentChangeRequest> {
  /**
   * Required. The ID of the account where the document is located.
   *
   * @generated from field: string account = 1;
   */
  account = "";

  /**
   * Required. Path of the document to create change for.
   * If document doesn't exist it will be created.
   * Empty string means root document.
   *
   * @generated from field: string path = 2;
   */
  path = "";

  /**
   * Required. Version of the document to apply changes to.
   * Can be empty when creating a new document.
   *
   * @generated from field: string base_version = 3;
   */
  baseVersion = "";

  /**
   * Required. Changes to be applied to the document.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.DocumentChange changes = 4;
   */
  changes: DocumentChange[] = [];

  /**
   * Required. Name of the key to use for signing.
   * Use the Daemon API to list and manage keys.
   *
   * @generated from field: string signing_key_name = 5;
   */
  signingKeyName = "";

  /**
   * Optional. ID of the capability that allows signing key to write on behalf of the account
   * for this particular path.
   *
   * @generated from field: string capability = 6;
   */
  capability = "";

  /**
   * Optional. Timestamp that the change should have.
   * By default current time is used.
   * The timestamp must be strictly greater than the timestamp of any other Change in the dependency chain.
   *
   * @generated from field: google.protobuf.Timestamp timestamp = 7;
   */
  timestamp?: Timestamp;

  constructor(data?: PartialMessage<CreateDocumentChangeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.CreateDocumentChangeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "base_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "changes", kind: "message", T: DocumentChange, repeated: true },
    { no: 5, name: "signing_key_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "capability", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "timestamp", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDocumentChangeRequest {
    return new CreateDocumentChangeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDocumentChangeRequest {
    return new CreateDocumentChangeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDocumentChangeRequest {
    return new CreateDocumentChangeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDocumentChangeRequest | PlainMessage<CreateDocumentChangeRequest> | undefined, b: CreateDocumentChangeRequest | PlainMessage<CreateDocumentChangeRequest> | undefined): boolean {
    return proto3.util.equals(CreateDocumentChangeRequest, a, b);
  }
}

/**
 * @generated from message com.seed.documents.v3alpha.DeleteDocumentRequest
 */
export class DeleteDocumentRequest extends Message<DeleteDocumentRequest> {
  /**
   * Required. ID of the account to delete the document from.
   *
   * @generated from field: string account = 1;
   */
  account = "";

  /**
   * Required. Path of the document to delete.
   *
   * @generated from field: string path = 2;
   */
  path = "";

  constructor(data?: PartialMessage<DeleteDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.DeleteDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteDocumentRequest {
    return new DeleteDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteDocumentRequest {
    return new DeleteDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteDocumentRequest {
    return new DeleteDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteDocumentRequest | PlainMessage<DeleteDocumentRequest> | undefined, b: DeleteDocumentRequest | PlainMessage<DeleteDocumentRequest> | undefined): boolean {
    return proto3.util.equals(DeleteDocumentRequest, a, b);
  }
}

/**
 * Request for listing root documents.
 *
 * @generated from message com.seed.documents.v3alpha.ListRootDocumentsRequest
 */
export class ListRootDocumentsRequest extends Message<ListRootDocumentsRequest> {
  /**
   * Optional. Number of results per page. Default is defined by the server.
   *
   * @generated from field: int32 page_size = 1;
   */
  pageSize = 0;

  /**
   * Optional. Value from next_page_token obtained from a previous response.
   *
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListRootDocumentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListRootDocumentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRootDocumentsRequest {
    return new ListRootDocumentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRootDocumentsRequest {
    return new ListRootDocumentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRootDocumentsRequest {
    return new ListRootDocumentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRootDocumentsRequest | PlainMessage<ListRootDocumentsRequest> | undefined, b: ListRootDocumentsRequest | PlainMessage<ListRootDocumentsRequest> | undefined): boolean {
    return proto3.util.equals(ListRootDocumentsRequest, a, b);
  }
}

/**
 * Response for listing root documents.
 *
 * @generated from message com.seed.documents.v3alpha.ListRootDocumentsResponse
 */
export class ListRootDocumentsResponse extends Message<ListRootDocumentsResponse> {
  /**
   * List of root documents.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.DocumentInfo documents = 1;
   */
  documents: DocumentInfo[] = [];

  /**
   * Token for the next page if there're more results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListRootDocumentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListRootDocumentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "documents", kind: "message", T: DocumentInfo, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRootDocumentsResponse {
    return new ListRootDocumentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRootDocumentsResponse {
    return new ListRootDocumentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRootDocumentsResponse {
    return new ListRootDocumentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRootDocumentsResponse | PlainMessage<ListRootDocumentsResponse> | undefined, b: ListRootDocumentsResponse | PlainMessage<ListRootDocumentsResponse> | undefined): boolean {
    return proto3.util.equals(ListRootDocumentsResponse, a, b);
  }
}

/**
 * Request to list accounts.
 *
 * @generated from message com.seed.documents.v3alpha.ListAccountsRequest
 */
export class ListAccountsRequest extends Message<ListAccountsRequest> {
  /**
   * Optional. Number of results to return per page.
   * If not set, the server will pick some default number.
   *
   * @generated from field: int32 page_size = 1;
   */
  pageSize = 0;

  /**
   * Optional. Token of the page to request, obtained from
   * a previous response.
   *
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  /**
   * Optional. If set to true, only accounts that the user is subscribed to will be returned.
   * If at least one document in the account has a subscription, then the account is considered subscribed.
   *
   * @generated from field: bool subscribed_only = 3;
   */
  subscribedOnly = false;

  /**
   * Optional. Configuration for sorting the response.
   * If not specified, the default sorting is by activity time in descending order.
   *
   * @generated from field: com.seed.documents.v3alpha.SortOptions sort_options = 4;
   */
  sortOptions?: SortOptions;

  constructor(data?: PartialMessage<ListAccountsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListAccountsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "subscribed_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "sort_options", kind: "message", T: SortOptions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAccountsRequest {
    return new ListAccountsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAccountsRequest {
    return new ListAccountsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAccountsRequest {
    return new ListAccountsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAccountsRequest | PlainMessage<ListAccountsRequest> | undefined, b: ListAccountsRequest | PlainMessage<ListAccountsRequest> | undefined): boolean {
    return proto3.util.equals(ListAccountsRequest, a, b);
  }
}

/**
 * Response to list accounts.
 *
 * @generated from message com.seed.documents.v3alpha.ListAccountsResponse
 */
export class ListAccountsResponse extends Message<ListAccountsResponse> {
  /**
   * List of accounts.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.Account accounts = 1;
   */
  accounts: Account[] = [];

  /**
   * Token for the next page if there're more results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListAccountsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListAccountsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accounts", kind: "message", T: Account, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAccountsResponse {
    return new ListAccountsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAccountsResponse {
    return new ListAccountsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAccountsResponse {
    return new ListAccountsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAccountsResponse | PlainMessage<ListAccountsResponse> | undefined, b: ListAccountsResponse | PlainMessage<ListAccountsResponse> | undefined): boolean {
    return proto3.util.equals(ListAccountsResponse, a, b);
  }
}

/**
 * Aggregated information about an account.
 * Some of this information comes from the accounts' root document,
 * some is aggregated from the other documents inside of the account.
 *
 * @generated from message com.seed.documents.v3alpha.Account
 */
export class Account extends Message<Account> {
  /**
   * ID of the account.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Metadata attributes from the root document of the account.
   *
   * @generated from field: google.protobuf.Struct metadata = 2;
   */
  metadata?: Struct;

  /**
   * Summary of the activity in the entire account.
   *
   * @generated from field: com.seed.documents.v3alpha.ActivitySummary activity_summary = 3;
   */
  activitySummary?: ActivitySummary;

  /**
   * Whether the user is subscribed to this account.
   * If at least one document in the account has a subscription, then the account is considered subscribed.
   *
   * @generated from field: bool is_subscribed = 4;
   */
  isSubscribed = false;

  constructor(data?: PartialMessage<Account>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.Account";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: Struct },
    { no: 3, name: "activity_summary", kind: "message", T: ActivitySummary },
    { no: 4, name: "is_subscribed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Account {
    return new Account().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJsonString(jsonString, options);
  }

  static equals(a: Account | PlainMessage<Account> | undefined, b: Account | PlainMessage<Account> | undefined): boolean {
    return proto3.util.equals(Account, a, b);
  }
}

/**
 * Request to list a directory.
 *
 * @generated from message com.seed.documents.v3alpha.ListDirectoryRequest
 */
export class ListDirectoryRequest extends Message<ListDirectoryRequest> {
  /**
   * Optional. Size of the page.
   *
   * @generated from field: int32 page_size = 1;
   */
  pageSize = 0;

  /**
   * Optional. Pagination token.
   *
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  /**
   * Required. ID of the account to list the directory for.
   *
   * @generated from field: string account = 3;
   */
  account = "";

  /**
   * Required. Path of the directory to list documents for.
   * Empty string means root directory.
   *
   * @generated from field: string directory_path = 4;
   */
  directoryPath = "";

  /**
   * Optional. If true, list documents recursively.
   *
   * @generated from field: bool recursive = 5;
   */
  recursive = false;

  /**
   * Optional. If set to true, only documents that the user is subscribed to will be returned.
   *
   * @generated from field: bool subscribed_only = 6;
   */
  subscribedOnly = false;

  /**
   * Optional. Configuration for sorting.
   * If not specified, the default sorting is by activity time in descending order.
   *
   * @generated from field: com.seed.documents.v3alpha.SortOptions sort_options = 7;
   */
  sortOptions?: SortOptions;

  constructor(data?: PartialMessage<ListDirectoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListDirectoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "directory_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "recursive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "subscribed_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "sort_options", kind: "message", T: SortOptions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDirectoryRequest {
    return new ListDirectoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDirectoryRequest {
    return new ListDirectoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDirectoryRequest {
    return new ListDirectoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListDirectoryRequest | PlainMessage<ListDirectoryRequest> | undefined, b: ListDirectoryRequest | PlainMessage<ListDirectoryRequest> | undefined): boolean {
    return proto3.util.equals(ListDirectoryRequest, a, b);
  }
}

/**
 * Options for sorting list of documents.
 *
 * @generated from message com.seed.documents.v3alpha.SortOptions
 */
export class SortOptions extends Message<SortOptions> {
  /**
   * Attributes by which the list can be sorted.
   *
   * @generated from field: com.seed.documents.v3alpha.SortAttribute attribute = 1;
   */
  attribute = SortAttribute.ACTIVITY_TIME;

  /**
   * By default sort is ascending (smaller values first).
   * This field can be specified to choose the opposing sorting.
   *
   * @generated from field: bool descending = 2;
   */
  descending = false;

  constructor(data?: PartialMessage<SortOptions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.SortOptions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "attribute", kind: "enum", T: proto3.getEnumType(SortAttribute) },
    { no: 2, name: "descending", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SortOptions {
    return new SortOptions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SortOptions {
    return new SortOptions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SortOptions {
    return new SortOptions().fromJsonString(jsonString, options);
  }

  static equals(a: SortOptions | PlainMessage<SortOptions> | undefined, b: SortOptions | PlainMessage<SortOptions> | undefined): boolean {
    return proto3.util.equals(SortOptions, a, b);
  }
}

/**
 * Response of the directory list.
 *
 * @generated from message com.seed.documents.v3alpha.ListDirectoryResponse
 */
export class ListDirectoryResponse extends Message<ListDirectoryResponse> {
  /**
   * List of documents.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.DocumentInfo documents = 1;
   */
  documents: DocumentInfo[] = [];

  /**
   * Optional. Token for the next page if there're more results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListDirectoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListDirectoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "documents", kind: "message", T: DocumentInfo, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDirectoryResponse {
    return new ListDirectoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDirectoryResponse {
    return new ListDirectoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDirectoryResponse {
    return new ListDirectoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListDirectoryResponse | PlainMessage<ListDirectoryResponse> | undefined, b: ListDirectoryResponse | PlainMessage<ListDirectoryResponse> | undefined): boolean {
    return proto3.util.equals(ListDirectoryResponse, a, b);
  }
}

/**
 * Request for listing documents.
 *
 * @generated from message com.seed.documents.v3alpha.ListDocumentsRequest
 */
export class ListDocumentsRequest extends Message<ListDocumentsRequest> {
  /**
   * Optional. ID of the account to list documents for.
   * If not specified, all documents are listed.
   *
   * @generated from field: string account = 1;
   */
  account = "";

  /**
   * Optional. Number of results per page. Default is defined by the server.
   *
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * Optional. Value from next_page_token obtained from a previous response.
   *
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListDocumentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListDocumentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDocumentsRequest {
    return new ListDocumentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDocumentsRequest {
    return new ListDocumentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDocumentsRequest {
    return new ListDocumentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListDocumentsRequest | PlainMessage<ListDocumentsRequest> | undefined, b: ListDocumentsRequest | PlainMessage<ListDocumentsRequest> | undefined): boolean {
    return proto3.util.equals(ListDocumentsRequest, a, b);
  }
}

/**
 * Response with list of documents.
 *
 * @generated from message com.seed.documents.v3alpha.ListDocumentsResponse
 */
export class ListDocumentsResponse extends Message<ListDocumentsResponse> {
  /**
   * List of documents.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.DocumentInfo documents = 1;
   */
  documents: DocumentInfo[] = [];

  /**
   * Token for the next page if there're more results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListDocumentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListDocumentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "documents", kind: "message", T: DocumentInfo, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDocumentsResponse {
    return new ListDocumentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDocumentsResponse {
    return new ListDocumentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDocumentsResponse {
    return new ListDocumentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListDocumentsResponse | PlainMessage<ListDocumentsResponse> | undefined, b: ListDocumentsResponse | PlainMessage<ListDocumentsResponse> | undefined): boolean {
    return proto3.util.equals(ListDocumentsResponse, a, b);
  }
}

/**
 * Request for listing document changes.
 *
 * @generated from message com.seed.documents.v3alpha.ListDocumentChangesRequest
 */
export class ListDocumentChangesRequest extends Message<ListDocumentChangesRequest> {
  /**
   * Required. ID of the account to list versions for.
   *
   * @generated from field: string account = 1;
   */
  account = "";

  /**
   * Required. Path of the document to list versions for.
   *
   * @generated from field: string path = 2;
   */
  path = "";

  /**
   * Required. Version of the document to list changes for.
   *
   * @generated from field: string version = 3;
   */
  version = "";

  /**
   * Optional. Number of results per page. Default is defined by the server.
   *
   * @generated from field: int32 page_size = 4;
   */
  pageSize = 0;

  /**
   * Optional. Value from next_page_token obtained from a previous response.
   *
   * @generated from field: string page_token = 5;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListDocumentChangesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListDocumentChangesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDocumentChangesRequest {
    return new ListDocumentChangesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDocumentChangesRequest {
    return new ListDocumentChangesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDocumentChangesRequest {
    return new ListDocumentChangesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListDocumentChangesRequest | PlainMessage<ListDocumentChangesRequest> | undefined, b: ListDocumentChangesRequest | PlainMessage<ListDocumentChangesRequest> | undefined): boolean {
    return proto3.util.equals(ListDocumentChangesRequest, a, b);
  }
}

/**
 * Response with list of document versions.
 *
 * @generated from message com.seed.documents.v3alpha.ListDocumentChangesResponse
 */
export class ListDocumentChangesResponse extends Message<ListDocumentChangesResponse> {
  /**
   * List of document versions.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.DocumentChangeInfo changes = 1;
   */
  changes: DocumentChangeInfo[] = [];

  /**
   * Token for the next page if there're more results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListDocumentChangesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ListDocumentChangesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "changes", kind: "message", T: DocumentChangeInfo, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDocumentChangesResponse {
    return new ListDocumentChangesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDocumentChangesResponse {
    return new ListDocumentChangesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDocumentChangesResponse {
    return new ListDocumentChangesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListDocumentChangesResponse | PlainMessage<ListDocumentChangesResponse> | undefined, b: ListDocumentChangesResponse | PlainMessage<ListDocumentChangesResponse> | undefined): boolean {
    return proto3.util.equals(ListDocumentChangesResponse, a, b);
  }
}

/**
 * Request to update document's read status.
 *
 * @generated from message com.seed.documents.v3alpha.UpdateDocumentReadStatusRequest
 */
export class UpdateDocumentReadStatusRequest extends Message<UpdateDocumentReadStatusRequest> {
  /**
   * Required. ID of the account to update the document in.
   *
   * @generated from field: string account = 1;
   */
  account = "";

  /**
   * Required. Path of the document to update.
   *
   * @generated from field: string path = 2;
   */
  path = "";

  /**
   * Required. New read status of the document.
   *
   * @generated from field: bool is_read = 3;
   */
  isRead = false;

  /**
   * Optional. Whether the status should be updated recursively for children of the path.
   *
   * @generated from field: bool is_recursive = 4;
   */
  isRecursive = false;

  constructor(data?: PartialMessage<UpdateDocumentReadStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.UpdateDocumentReadStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_read", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "is_recursive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDocumentReadStatusRequest {
    return new UpdateDocumentReadStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDocumentReadStatusRequest {
    return new UpdateDocumentReadStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDocumentReadStatusRequest {
    return new UpdateDocumentReadStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDocumentReadStatusRequest | PlainMessage<UpdateDocumentReadStatusRequest> | undefined, b: UpdateDocumentReadStatusRequest | PlainMessage<UpdateDocumentReadStatusRequest> | undefined): boolean {
    return proto3.util.equals(UpdateDocumentReadStatusRequest, a, b);
  }
}

/**
 * Request to create a Ref.
 *
 * @generated from message com.seed.documents.v3alpha.CreateRefRequest
 */
export class CreateRefRequest extends Message<CreateRefRequest> {
  /**
   * Required. The ID of the account/space in which to create the Ref.
   *
   * @generated from field: string account = 1;
   */
  account = "";

  /**
   * Required. Path of the new Ref.
   * Empty string means root document.
   *
   * @generated from field: string path = 2;
   */
  path = "";

  /**
   * Required. Target for the new Ref.
   *
   * @generated from field: com.seed.documents.v3alpha.RefTarget target = 3;
   */
  target?: RefTarget;

  /**
   * Required. Name of the signing key to use for signing the Ref.
   *
   * @generated from field: string signing_key_name = 4;
   */
  signingKeyName = "";

  /**
   * Optional. ID of the Capability blob that grants the necessary rights to the signin key
   * to write Refs for the requested account + path.
   *
   * @generated from field: string capability = 5;
   */
  capability = "";

  /**
   * Optional. A timestamp of the Ref blob.
   * If not specified the server will use the current time.
   * The provided timestamp will be rounded to the nearest millisecond,
   * so the final timestamp in the resulting Ref blob may not be exactly as provided.
   *
   * @generated from field: google.protobuf.Timestamp timestamp = 6;
   */
  timestamp?: Timestamp;

  /**
   * Optional. The generation number for the Ref.
   * If not specified, the server will use the generation number of the most recent generation,
   * or will create a fresh generation number if there are no existing Refs for this path.
   *
   * @generated from field: int64 generation = 7;
   */
  generation = protoInt64.zero;

  constructor(data?: PartialMessage<CreateRefRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.CreateRefRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "target", kind: "message", T: RefTarget },
    { no: 4, name: "signing_key_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "capability", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "timestamp", kind: "message", T: Timestamp },
    { no: 7, name: "generation", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRefRequest {
    return new CreateRefRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRefRequest {
    return new CreateRefRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRefRequest {
    return new CreateRefRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRefRequest | PlainMessage<CreateRefRequest> | undefined, b: CreateRefRequest | PlainMessage<CreateRefRequest> | undefined): boolean {
    return proto3.util.equals(CreateRefRequest, a, b);
  }
}

/**
 * Request to get a Ref by ID.
 *
 * @generated from message com.seed.documents.v3alpha.GetRefRequest
 */
export class GetRefRequest extends Message<GetRefRequest> {
  /**
   * Required. ID of the Ref blob to retrieve.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetRefRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.GetRefRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRefRequest {
    return new GetRefRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRefRequest {
    return new GetRefRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRefRequest {
    return new GetRefRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRefRequest | PlainMessage<GetRefRequest> | undefined, b: GetRefRequest | PlainMessage<GetRefRequest> | undefined): boolean {
    return proto3.util.equals(GetRefRequest, a, b);
  }
}

/**
 * Information about a particular document version.
 *
 * @generated from message com.seed.documents.v3alpha.DocumentChangeInfo
 */
export class DocumentChangeInfo extends Message<DocumentChangeInfo> {
  /**
   * CID of the change.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Author of the change.
   *
   * @generated from field: string author = 2;
   */
  author = "";

  /**
   * List of change IDs that this change depends on.
   *
   * @generated from field: repeated string deps = 3;
   */
  deps: string[] = [];

  /**
   * Time when the change was created (as claimed by the author).
   *
   * @generated from field: google.protobuf.Timestamp create_time = 4;
   */
  createTime?: Timestamp;

  constructor(data?: PartialMessage<DocumentChangeInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.DocumentChangeInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "author", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "deps", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "create_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DocumentChangeInfo {
    return new DocumentChangeInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DocumentChangeInfo {
    return new DocumentChangeInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DocumentChangeInfo {
    return new DocumentChangeInfo().fromJsonString(jsonString, options);
  }

  static equals(a: DocumentChangeInfo | PlainMessage<DocumentChangeInfo> | undefined, b: DocumentChangeInfo | PlainMessage<DocumentChangeInfo> | undefined): boolean {
    return proto3.util.equals(DocumentChangeInfo, a, b);
  }
}

/**
 * Basic data about a document with some aggregations and metadata.
 * It's like Document, without the content, but with some additional info.
 *
 * @generated from message com.seed.documents.v3alpha.DocumentInfo
 */
export class DocumentInfo extends Message<DocumentInfo> {
  /**
   * Account to which the document belongs.
   *
   * @generated from field: string account = 1;
   */
  account = "";

  /**
   * Path of the document within the account.
   * Empty string means root document.
   *
   * @generated from field: string path = 2;
   */
  path = "";

  /**
   * User-defined metadata attributes of the document.
   *
   * @generated from field: google.protobuf.Struct metadata = 3;
   */
  metadata?: Struct;

  /**
   * Every author ID who has ever made changes to this document.
   *
   * @generated from field: repeated string authors = 4;
   */
  authors: string[] = [];

  /**
   * Time when the document was created.
   *
   * @generated from field: google.protobuf.Timestamp create_time = 5;
   */
  createTime?: Timestamp;

  /**
   * Time when the document was updated.
   * I.e. timestamp of the most recent change.
   *
   * @generated from field: google.protobuf.Timestamp update_time = 6;
   */
  updateTime?: Timestamp;

  /**
   * ID of the genesis change of this document.
   *
   * @generated from field: string genesis = 8;
   */
  genesis = "";

  /**
   * Current version of the document.
   *
   * @generated from field: string version = 9;
   */
  version = "";

  /**
   * Breadcrumbs of this document.
   * The number of items in this list corresponds to the number of segments
   * in the path of the document minus 1 (the document itself).
   *
   * E.g. for a document "/cars/jp/honda" there will be 2 breadcrumbs: "cars" and "jp".
   *
   * If we don't have a document for some of the path segements for whatever reason,
   * the corresponding breadcrumb segment will be null.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.Breadcrumb breadcrumbs = 10;
   */
  breadcrumbs: Breadcrumb[] = [];

  /**
   * Summary of the activity on the document.
   *
   * @generated from field: com.seed.documents.v3alpha.ActivitySummary activity_summary = 11;
   */
  activitySummary?: ActivitySummary;

  /**
   * Information about the generation of the document.
   *
   * @generated from field: com.seed.documents.v3alpha.GenerationInfo generation_info = 12;
   */
  generationInfo?: GenerationInfo;

  constructor(data?: PartialMessage<DocumentInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.DocumentInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "metadata", kind: "message", T: Struct },
    { no: 4, name: "authors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "create_time", kind: "message", T: Timestamp },
    { no: 6, name: "update_time", kind: "message", T: Timestamp },
    { no: 8, name: "genesis", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "breadcrumbs", kind: "message", T: Breadcrumb, repeated: true },
    { no: 11, name: "activity_summary", kind: "message", T: ActivitySummary },
    { no: 12, name: "generation_info", kind: "message", T: GenerationInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DocumentInfo {
    return new DocumentInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DocumentInfo {
    return new DocumentInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DocumentInfo {
    return new DocumentInfo().fromJsonString(jsonString, options);
  }

  static equals(a: DocumentInfo | PlainMessage<DocumentInfo> | undefined, b: DocumentInfo | PlainMessage<DocumentInfo> | undefined): boolean {
    return proto3.util.equals(DocumentInfo, a, b);
  }
}

/**
 * Information about the generation of a document.
 *
 * @generated from message com.seed.documents.v3alpha.GenerationInfo
 */
export class GenerationInfo extends Message<GenerationInfo> {
  /**
   * CID of the genesis change.
   *
   * @generated from field: string genesis = 1;
   */
  genesis = "";

  /**
   * A generation number of the document.
   * Usually it's a timestamp of the first time the generation is created,
   * but it shouldn't be used as such, and should instead be treated as an opaque value.
   *
   * @generated from field: int64 generation = 2;
   */
  generation = protoInt64.zero;

  constructor(data?: PartialMessage<GenerationInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.GenerationInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "genesis", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "generation", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerationInfo {
    return new GenerationInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerationInfo {
    return new GenerationInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerationInfo {
    return new GenerationInfo().fromJsonString(jsonString, options);
  }

  static equals(a: GenerationInfo | PlainMessage<GenerationInfo> | undefined, b: GenerationInfo | PlainMessage<GenerationInfo> | undefined): boolean {
    return proto3.util.equals(GenerationInfo, a, b);
  }
}

/**
 * Summary of the activity on a document or account.
 *
 * @generated from message com.seed.documents.v3alpha.ActivitySummary
 */
export class ActivitySummary extends Message<ActivitySummary> {
  /**
   * Timestamp of the most recent comment.
   *
   * @generated from field: google.protobuf.Timestamp latest_comment_time = 1;
   */
  latestCommentTime?: Timestamp;

  /**
   * ID of the most recent comment.
   *
   * @generated from field: string latest_comment_id = 4;
   */
  latestCommentId = "";

  /**
   * Total number of comments.
   *
   * @generated from field: int32 comment_count = 2;
   */
  commentCount = 0;

  /**
   * Timestamp of the most recent change.
   *
   * @generated from field: google.protobuf.Timestamp latest_change_time = 3;
   */
  latestChangeTime?: Timestamp;

  /**
   * Whether there's any unread activity on this document/account.
   *
   * @generated from field: bool is_unread = 5;
   */
  isUnread = false;

  constructor(data?: PartialMessage<ActivitySummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.ActivitySummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "latest_comment_time", kind: "message", T: Timestamp },
    { no: 4, name: "latest_comment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "comment_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "latest_change_time", kind: "message", T: Timestamp },
    { no: 5, name: "is_unread", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivitySummary {
    return new ActivitySummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivitySummary {
    return new ActivitySummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivitySummary {
    return new ActivitySummary().fromJsonString(jsonString, options);
  }

  static equals(a: ActivitySummary | PlainMessage<ActivitySummary> | undefined, b: ActivitySummary | PlainMessage<ActivitySummary> | undefined): boolean {
    return proto3.util.equals(ActivitySummary, a, b);
  }
}

/**
 * Basic information about ancestor documents in the path hierarchy.
 *
 * @generated from message com.seed.documents.v3alpha.Breadcrumb
 */
export class Breadcrumb extends Message<Breadcrumb> {
  /**
   * Title of the document.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Path of the document.
   *
   * @generated from field: string path = 2;
   */
  path = "";

  /**
   * Will be true if we don't have any indexed information about this document.
   *
   * @generated from field: bool is_missing = 3;
   */
  isMissing = false;

  constructor(data?: PartialMessage<Breadcrumb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.Breadcrumb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_missing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Breadcrumb {
    return new Breadcrumb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Breadcrumb {
    return new Breadcrumb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Breadcrumb {
    return new Breadcrumb().fromJsonString(jsonString, options);
  }

  static equals(a: Breadcrumb | PlainMessage<Breadcrumb> | undefined, b: Breadcrumb | PlainMessage<Breadcrumb> | undefined): boolean {
    return proto3.util.equals(Breadcrumb, a, b);
  }
}

/**
 * Document represents metadata and content of a document.
 *
 * @generated from message com.seed.documents.v3alpha.Document
 */
export class Document extends Message<Document> {
  /**
   * Account to which the document belongs.
   *
   * @generated from field: string account = 1;
   */
  account = "";

  /**
   * Path of the document within the account.
   * Empty string means root document.
   *
   * @generated from field: string path = 2;
   */
  path = "";

  /**
   * Metadata values for a document.
   *
   * @generated from field: google.protobuf.Struct metadata = 3;
   */
  metadata?: Struct;

  /**
   * Output only. Every account ID who has modified the document.
   * Includes the original author as well.
   *
   * @generated from field: repeated string authors = 5;
   */
  authors: string[] = [];

  /**
   * Special block for the document header.
   * Implicitly the ID of this block is a constant string "header".
   *
   * @generated from field: com.seed.documents.v3alpha.BlockNode header = 11;
   */
  header?: BlockNode;

  /**
   * Blocks content of the document.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.BlockNode content = 6;
   */
  content: BlockNode[] = [];

  /**
   * Special block for the document footer.
   * Implicitly the ID of this block is a constant string "footer".
   *
   * @generated from field: com.seed.documents.v3alpha.BlockNode footer = 12;
   */
  footer?: BlockNode;

  /**
   * Output only. Time when document was created.
   *
   * @generated from field: google.protobuf.Timestamp create_time = 7;
   */
  createTime?: Timestamp;

  /**
   * Output only. Time when document was updated.
   *
   * @generated from field: google.protobuf.Timestamp update_time = 8;
   */
  updateTime?: Timestamp;

  /**
   * Output only. ID of the genesis change of this document.
   *
   * @generated from field: string genesis = 9;
   */
  genesis = "";

  /**
   * Output only. Current version of the document.
   *
   * @generated from field: string version = 10;
   */
  version = "";

  /**
   * Information about the generation of the document.
   *
   * @generated from field: com.seed.documents.v3alpha.GenerationInfo generation_info = 13;
   */
  generationInfo?: GenerationInfo;

  constructor(data?: PartialMessage<Document>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.Document";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "metadata", kind: "message", T: Struct },
    { no: 5, name: "authors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "header", kind: "message", T: BlockNode },
    { no: 6, name: "content", kind: "message", T: BlockNode, repeated: true },
    { no: 12, name: "footer", kind: "message", T: BlockNode },
    { no: 7, name: "create_time", kind: "message", T: Timestamp },
    { no: 8, name: "update_time", kind: "message", T: Timestamp },
    { no: 9, name: "genesis", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "generation_info", kind: "message", T: GenerationInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Document {
    return new Document().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Document {
    return new Document().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Document {
    return new Document().fromJsonString(jsonString, options);
  }

  static equals(a: Document | PlainMessage<Document> | undefined, b: Document | PlainMessage<Document> | undefined): boolean {
    return proto3.util.equals(Document, a, b);
  }
}

/**
 * Content block with children.
 *
 * @generated from message com.seed.documents.v3alpha.BlockNode
 */
export class BlockNode extends Message<BlockNode> {
  /**
   * Content block.
   *
   * @generated from field: com.seed.documents.v3alpha.Block block = 1;
   */
  block?: Block;

  /**
   * Child blocks.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.BlockNode children = 2;
   */
  children: BlockNode[] = [];

  constructor(data?: PartialMessage<BlockNode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.BlockNode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "block", kind: "message", T: Block },
    { no: 2, name: "children", kind: "message", T: BlockNode, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BlockNode {
    return new BlockNode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BlockNode {
    return new BlockNode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BlockNode {
    return new BlockNode().fromJsonString(jsonString, options);
  }

  static equals(a: BlockNode | PlainMessage<BlockNode> | undefined, b: BlockNode | PlainMessage<BlockNode> | undefined): boolean {
    return proto3.util.equals(BlockNode, a, b);
  }
}

/**
 * Content block.
 *
 * @generated from message com.seed.documents.v3alpha.Block
 */
export class Block extends Message<Block> {
  /**
   * Block ID. Must be unique within the document.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Type of the block. Specific to the renderer.
   *
   * @generated from field: string type = 2;
   */
  type = "";

  /**
   * Text of the content block.
   *
   * @generated from field: string text = 3;
   */
  text = "";

  /**
   * Optional. The hyperlink to an external resource.
   * Must be a valid URL.
   *
   * @generated from field: string link = 7;
   */
  link = "";

  /**
   * Remaining type-specific attribtues of the block.
   * Logically, these attributes are on the same level as the other fields,
   * i.e. the field `attributes` should not exist,
   * but because protobuf doesn't support arbitrary fields, and we don't want/need
   * to specify the types of all the possible fields, we use this approach of storing
   * the rest of the fields in this open-ended attributes map.
   * The side-effect of this is that `attributes` map must not have any keys
   * that conflict with the names of the top-level fields.
   *
   * @generated from field: google.protobuf.Struct attributes = 4;
   */
  attributes?: Struct;

  /**
   * Annotation "layers" of the block.
   *
   * @generated from field: repeated com.seed.documents.v3alpha.Annotation annotations = 5;
   */
  annotations: Annotation[] = [];

  /**
   * Output only. Current revision of the block. It's the ID of the last Change that modified this block.
   * Additional information about the Change can be obtained using the Changes service.
   *
   * @generated from field: string revision = 6;
   */
  revision = "";

  constructor(data?: PartialMessage<Block>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.Block";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "attributes", kind: "message", T: Struct },
    { no: 5, name: "annotations", kind: "message", T: Annotation, repeated: true },
    { no: 6, name: "revision", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Block {
    return new Block().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Block {
    return new Block().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Block {
    return new Block().fromJsonString(jsonString, options);
  }

  static equals(a: Block | PlainMessage<Block> | undefined, b: Block | PlainMessage<Block> | undefined): boolean {
    return proto3.util.equals(Block, a, b);
  }
}

/**
 * Conceptual annotation "layer" that is applied to arbitrary spans of block text.
 * An "identity" of the layer should be derived deterministically based on its type
 * attributes. Spans inside the same annotation can't overlap.
 *
 * Spans are stored inside the Annotation in a "columnar" format,
 * i.e. StructureOfArrays instead of ArrayOfStructures. See: https://en.wikipedia.org/wiki/AoS_and_SoA.
 * This is useful to reduce the number of allocations and offers more compact serialization, because
 * protobuf is able to "pack" primitive repeated fields more efficiently.
 *
 * @generated from message com.seed.documents.v3alpha.Annotation
 */
export class Annotation extends Message<Annotation> {
  /**
   * Type of the annotation.
   *
   * @generated from field: string type = 1;
   */
  type = "";

  /**
   * Optional. A hyperlink to an external resource.
   * Must be a valid URL.
   *
   * @generated from field: string link = 5;
   */
  link = "";

  /**
   * Arbitrary key-value attributes of the annotation.
   *
   * @generated from field: google.protobuf.Struct attributes = 2;
   */
  attributes?: Struct;

  /**
   * Start offsets of possibly disjoint spans of text for which this annotation is applied.
   * Must be sorted and have the same number of items as `ends` list.
   *
   * @generated from field: repeated int32 starts = 3;
   */
  starts: number[] = [];

  /**
   * End offsets of possibly disjoint spans of text for which this annotation is applied.
   * Must be sorted and have the same number of items as `starts` list.
   *
   * @generated from field: repeated int32 ends = 4;
   */
  ends: number[] = [];

  constructor(data?: PartialMessage<Annotation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.Annotation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "attributes", kind: "message", T: Struct },
    { no: 3, name: "starts", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "ends", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Annotation {
    return new Annotation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Annotation {
    return new Annotation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Annotation {
    return new Annotation().fromJsonString(jsonString, options);
  }

  static equals(a: Annotation | PlainMessage<Annotation> | undefined, b: Annotation | PlainMessage<Annotation> | undefined): boolean {
    return proto3.util.equals(Annotation, a, b);
  }
}

/**
 * Granular document change.
 *
 * @generated from message com.seed.documents.v3alpha.DocumentChange
 */
export class DocumentChange extends Message<DocumentChange> {
  /**
   * @generated from oneof com.seed.documents.v3alpha.DocumentChange.op
   */
  op: {
    /**
     * New metadata to set on the document.
     *
     * Deprecated: Use SetAttribute instead.
     *
     * @generated from field: com.seed.documents.v3alpha.DocumentChange.SetMetadata set_metadata = 1;
     */
    value: DocumentChange_SetMetadata;
    case: "setMetadata";
  } | {
    /**
     * Move operation that creates/moves a block within the document hierarchy.
     *
     * @generated from field: com.seed.documents.v3alpha.DocumentChange.MoveBlock move_block = 2;
     */
    value: DocumentChange_MoveBlock;
    case: "moveBlock";
  } | {
    /**
     * New block state that replaces an existing block.
     *
     * @generated from field: com.seed.documents.v3alpha.Block replace_block = 3;
     */
    value: Block;
    case: "replaceBlock";
  } | {
    /**
     * ID of a block to delete.
     *
     * @generated from field: string delete_block = 4;
     */
    value: string;
    case: "deleteBlock";
  } | {
    /**
     * Sets an attribute on a block.
     *
     * @generated from field: com.seed.documents.v3alpha.DocumentChange.SetAttribute set_attribute = 5;
     */
    value: DocumentChange_SetAttribute;
    case: "setAttribute";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<DocumentChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.DocumentChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set_metadata", kind: "message", T: DocumentChange_SetMetadata, oneof: "op" },
    { no: 2, name: "move_block", kind: "message", T: DocumentChange_MoveBlock, oneof: "op" },
    { no: 3, name: "replace_block", kind: "message", T: Block, oneof: "op" },
    { no: 4, name: "delete_block", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "op" },
    { no: 5, name: "set_attribute", kind: "message", T: DocumentChange_SetAttribute, oneof: "op" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DocumentChange {
    return new DocumentChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DocumentChange {
    return new DocumentChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DocumentChange {
    return new DocumentChange().fromJsonString(jsonString, options);
  }

  static equals(a: DocumentChange | PlainMessage<DocumentChange> | undefined, b: DocumentChange | PlainMessage<DocumentChange> | undefined): boolean {
    return proto3.util.equals(DocumentChange, a, b);
  }
}

/**
 * Operation to move an existing block to a different place in the document.
 * Move and Create operations are both expressed with this.
 * Conceptually new blocks are moved out of nowhere into the document.
 *
 * @generated from message com.seed.documents.v3alpha.DocumentChange.MoveBlock
 */
export class DocumentChange_MoveBlock extends Message<DocumentChange_MoveBlock> {
  /**
   * ID of the block to move.
   *
   * @generated from field: string block_id = 1;
   */
  blockId = "";

  /**
   * ID of the new parent for the block being moved.
   *
   * @generated from field: string parent = 2;
   */
  parent = "";

  /**
   * ID of the new left sibling for the block being moved.
   *
   * @generated from field: string left_sibling = 3;
   */
  leftSibling = "";

  constructor(data?: PartialMessage<DocumentChange_MoveBlock>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.DocumentChange.MoveBlock";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "block_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "left_sibling", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DocumentChange_MoveBlock {
    return new DocumentChange_MoveBlock().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DocumentChange_MoveBlock {
    return new DocumentChange_MoveBlock().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DocumentChange_MoveBlock {
    return new DocumentChange_MoveBlock().fromJsonString(jsonString, options);
  }

  static equals(a: DocumentChange_MoveBlock | PlainMessage<DocumentChange_MoveBlock> | undefined, b: DocumentChange_MoveBlock | PlainMessage<DocumentChange_MoveBlock> | undefined): boolean {
    return proto3.util.equals(DocumentChange_MoveBlock, a, b);
  }
}

/**
 * Operation to replace a metadata field with a new value
 *
 * Deprecated: Use SetAttribute instead.
 *
 * @generated from message com.seed.documents.v3alpha.DocumentChange.SetMetadata
 */
export class DocumentChange_SetMetadata extends Message<DocumentChange_SetMetadata> {
  /**
   * Metadata key to set.
   *
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * Metadata value to set.
   *
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<DocumentChange_SetMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.DocumentChange.SetMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DocumentChange_SetMetadata {
    return new DocumentChange_SetMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DocumentChange_SetMetadata {
    return new DocumentChange_SetMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DocumentChange_SetMetadata {
    return new DocumentChange_SetMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: DocumentChange_SetMetadata | PlainMessage<DocumentChange_SetMetadata> | undefined, b: DocumentChange_SetMetadata | PlainMessage<DocumentChange_SetMetadata> | undefined): boolean {
    return proto3.util.equals(DocumentChange_SetMetadata, a, b);
  }
}

/**
 * Operation to set an attribute of a block or document.
 *
 * @generated from message com.seed.documents.v3alpha.DocumentChange.SetAttribute
 */
export class DocumentChange_SetAttribute extends Message<DocumentChange_SetAttribute> {
  /**
   * ID of the block to set the attribute on.
   *
   * Empty string means the document itself.
   * Special strings "footer" and "header" can be used to set attributes on the document footer and header blocks.
   *
   * @generated from field: string block_id = 1;
   */
  blockId = "";

  /**
   * A path of keys to set the value for.
   * It's a list to support nested attributes.
   *
   * @generated from field: repeated string key = 2;
   */
  key: string[] = [];

  /**
   * Value to set.
   *
   * @generated from oneof com.seed.documents.v3alpha.DocumentChange.SetAttribute.value
   */
  value: {
    /**
     * @generated from field: string string_value = 3;
     */
    value: string;
    case: "stringValue";
  } | {
    /**
     * @generated from field: int64 int_value = 4;
     */
    value: bigint;
    case: "intValue";
  } | {
    /**
     * @generated from field: bool bool_value = 5;
     */
    value: boolean;
    case: "boolValue";
  } | {
    /**
     * @generated from field: google.protobuf.Empty null_value = 6;
     */
    value: Empty;
    case: "nullValue";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<DocumentChange_SetAttribute>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.DocumentChange.SetAttribute";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "block_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "string_value", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
    { no: 4, name: "int_value", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "value" },
    { no: 5, name: "bool_value", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "value" },
    { no: 6, name: "null_value", kind: "message", T: Empty, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DocumentChange_SetAttribute {
    return new DocumentChange_SetAttribute().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DocumentChange_SetAttribute {
    return new DocumentChange_SetAttribute().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DocumentChange_SetAttribute {
    return new DocumentChange_SetAttribute().fromJsonString(jsonString, options);
  }

  static equals(a: DocumentChange_SetAttribute | PlainMessage<DocumentChange_SetAttribute> | undefined, b: DocumentChange_SetAttribute | PlainMessage<DocumentChange_SetAttribute> | undefined): boolean {
    return proto3.util.equals(DocumentChange_SetAttribute, a, b);
  }
}

/**
 * Description of a Ref blob.
 *
 * @generated from message com.seed.documents.v3alpha.Ref
 */
export class Ref extends Message<Ref> {
  /**
   * CID of the Ref blob.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Account (space) in which the Ref blob exists.
   *
   * @generated from field: string account = 2;
   */
  account = "";

  /**
   * Path in the account Ref creates an entry for.
   *
   * @generated from field: string path = 3;
   */
  path = "";

  /**
   * Description of where the Ref points to.
   *
   * @generated from field: com.seed.documents.v3alpha.RefTarget target = 4;
   */
  target?: RefTarget;

  /**
   * Public key used to sign the Ref blob.
   *
   * @generated from field: string signer = 5;
   */
  signer = "";

  /**
   * Optional. ID of the Capability attached to this Ref.
   *
   * @generated from field: string capability = 6;
   */
  capability = "";

  /**
   * Timestamp of the Ref.
   *
   * @generated from field: google.protobuf.Timestamp timestamp = 7;
   */
  timestamp?: Timestamp;

  /**
   * Information about the generation of the Ref.
   *
   * @generated from field: com.seed.documents.v3alpha.GenerationInfo generation_info = 8;
   */
  generationInfo?: GenerationInfo;

  constructor(data?: PartialMessage<Ref>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.Ref";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "target", kind: "message", T: RefTarget },
    { no: 5, name: "signer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "capability", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "timestamp", kind: "message", T: Timestamp },
    { no: 8, name: "generation_info", kind: "message", T: GenerationInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Ref {
    return new Ref().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Ref {
    return new Ref().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Ref {
    return new Ref().fromJsonString(jsonString, options);
  }

  static equals(a: Ref | PlainMessage<Ref> | undefined, b: Ref | PlainMessage<Ref> | undefined): boolean {
    return proto3.util.equals(Ref, a, b);
  }
}

/**
 * Description of where the Ref points to.
 *
 * @generated from message com.seed.documents.v3alpha.RefTarget
 */
export class RefTarget extends Message<RefTarget> {
  /**
   * A Ref can point to one of the following targets.
   *
   * @generated from oneof com.seed.documents.v3alpha.RefTarget.target
   */
  target: {
    /**
     * Version target describes genesis + head changes (version).
     *
     * @generated from field: com.seed.documents.v3alpha.RefTarget.Version version = 1;
     */
    value: RefTarget_Version;
    case: "version";
  } | {
    /**
     * Redirect target makes a Ref point to a different account + path.
     * Users must be careful to not create cycles or very deep redirect chains.
     * Client should be careful not to get stuck in redirects
     * by tracking visited paths to prevent cycles,
     * and by limiting the depth of the redirect chains,
     * while handling redirects.
     *
     * @generated from field: com.seed.documents.v3alpha.RefTarget.Redirect redirect = 2;
     */
    value: RefTarget_Redirect;
    case: "redirect";
  } | {
    /**
     * Tombstone Ref indicates the intent to delete the given account + path.
     *
     * @generated from field: com.seed.documents.v3alpha.RefTarget.Tombstone tombstone = 3;
     */
    value: RefTarget_Tombstone;
    case: "tombstone";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<RefTarget>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.RefTarget";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "version", kind: "message", T: RefTarget_Version, oneof: "target" },
    { no: 2, name: "redirect", kind: "message", T: RefTarget_Redirect, oneof: "target" },
    { no: 3, name: "tombstone", kind: "message", T: RefTarget_Tombstone, oneof: "target" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefTarget {
    return new RefTarget().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefTarget {
    return new RefTarget().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefTarget {
    return new RefTarget().fromJsonString(jsonString, options);
  }

  static equals(a: RefTarget | PlainMessage<RefTarget> | undefined, b: RefTarget | PlainMessage<RefTarget> | undefined): boolean {
    return proto3.util.equals(RefTarget, a, b);
  }
}

/**
 * @generated from message com.seed.documents.v3alpha.RefTarget.Version
 */
export class RefTarget_Version extends Message<RefTarget_Version> {
  /**
   * Required. ID of the genesis Change.
   *
   * @generated from field: string genesis = 1;
   */
  genesis = "";

  /**
   * Required. Version ID (possibly compount).
   * Each change in the version ID must have the same genesis.
   *
   * @generated from field: string version = 2;
   */
  version = "";

  constructor(data?: PartialMessage<RefTarget_Version>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.RefTarget.Version";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "genesis", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefTarget_Version {
    return new RefTarget_Version().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefTarget_Version {
    return new RefTarget_Version().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefTarget_Version {
    return new RefTarget_Version().fromJsonString(jsonString, options);
  }

  static equals(a: RefTarget_Version | PlainMessage<RefTarget_Version> | undefined, b: RefTarget_Version | PlainMessage<RefTarget_Version> | undefined): boolean {
    return proto3.util.equals(RefTarget_Version, a, b);
  }
}

/**
 * @generated from message com.seed.documents.v3alpha.RefTarget.Redirect
 */
export class RefTarget_Redirect extends Message<RefTarget_Redirect> {
  /**
   * Required. Account ID to which the Ref should redirect.
   * Can be the same as the account in the Ref itself,
   * when we redirect to a different path in the same account/space.
   *
   * @generated from field: string account = 1;
   */
  account = "";

  /**
   * Required. Path to which the Ref should redirect.
   * Empty string means root document.
   * Must not be the same as the Ref itself.
   *
   * @generated from field: string path = 2;
   */
  path = "";

  constructor(data?: PartialMessage<RefTarget_Redirect>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.RefTarget.Redirect";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefTarget_Redirect {
    return new RefTarget_Redirect().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefTarget_Redirect {
    return new RefTarget_Redirect().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefTarget_Redirect {
    return new RefTarget_Redirect().fromJsonString(jsonString, options);
  }

  static equals(a: RefTarget_Redirect | PlainMessage<RefTarget_Redirect> | undefined, b: RefTarget_Redirect | PlainMessage<RefTarget_Redirect> | undefined): boolean {
    return proto3.util.equals(RefTarget_Redirect, a, b);
  }
}

/**
 * @generated from message com.seed.documents.v3alpha.RefTarget.Tombstone
 */
export class RefTarget_Tombstone extends Message<RefTarget_Tombstone> {
  constructor(data?: PartialMessage<RefTarget_Tombstone>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "com.seed.documents.v3alpha.RefTarget.Tombstone";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefTarget_Tombstone {
    return new RefTarget_Tombstone().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefTarget_Tombstone {
    return new RefTarget_Tombstone().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefTarget_Tombstone {
    return new RefTarget_Tombstone().fromJsonString(jsonString, options);
  }

  static equals(a: RefTarget_Tombstone | PlainMessage<RefTarget_Tombstone> | undefined, b: RefTarget_Tombstone | PlainMessage<RefTarget_Tombstone> | undefined): boolean {
    return proto3.util.equals(RefTarget_Tombstone, a, b);
  }
}

