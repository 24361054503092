// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts,import_extension=none"
// @generated from file activity/v1alpha/activity.proto (package com.seed.activity.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListEventsRequest, ListEventsResponse } from "./activity_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * ActivityFeed service provides information about the recent activity events happened in the system.
 *
 * @generated from service com.seed.activity.v1alpha.ActivityFeed
 */
export const ActivityFeed = {
  typeName: "com.seed.activity.v1alpha.ActivityFeed",
  methods: {
    /**
     * Lists the recent activity events,
     * sorted by locally observed time (newest first).
     *
     * @generated from rpc com.seed.activity.v1alpha.ActivityFeed.ListEvents
     */
    listEvents: {
      name: "ListEvents",
      I: ListEventsRequest,
      O: ListEventsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

